(function() {
    angular.module('EntrakV5').controller('companyController', companyController);

    function companyController($scope, $rootScope, $timeout, Service, KEY, APIKEY, Api) {
        console.log('companyController');

        var caller = Api.createApiCaller();
        $rootScope.title = Service.translate("company.title");

        $scope.btnStatus = {};
        $scope.oauths = [];
        $scope.allowedDomains = [];
        $scope.heatIndexArr = [];
        $scope.heatIndexArrOrig = [];
        $scope.extendStep = KEY.timerStep;
        $scope.minExtend = KEY.timerStep;

        $scope.subscriptions = [];
        $scope.tenantId = null;

        $scope.externalTenantId = null;
        $scope.habitapId = null;
        $scope.currentHabitapId = null;
        $scope.isAdvancedPwd = false;
        $scope.isDisablePwd = false;
        $scope.allowSelfReg = false;
        $scope.allowViewOthers = false;
        $scope.allowVisitor = false;

        $scope.labelMap = {};
        $scope.labelMap[APIKEY.provider.google] = Service.translate("company.googleId");
        $scope.labelMap[APIKEY.provider.microsoft] = Service.translate("company.mircosoftId");

    /* first load */
        $rootScope.loadingPage = 0;
        $rootScope.getTenantId().then(function(tenantId){
            $scope.tenantId = tenantId;
            $scope.editMsTenantId(false);
            $scope.btnStatus.savingHabitapId = true;
            caller.call(Api.getTenantHabitapMapping(tenantId)).then(function(res){
                $scope.currentHabitapId = res ? res.clientId : null;
                $scope.editHabitapId(false);
                $scope.btnStatus.savingHabitapId = false;
            }, function(err){
                if (err === KEY.ignore)
                    return;
                $rootScope.generalErrorHandler(true);
                $scope.btnStatus.savingHabitapId = false;
            });
            $scope.compName = $rootScope.currentUser.tenant.name;
            $scope.apiKey = $rootScope.currentUser.tenant.apiKey;
            $scope.processOAuths($rootScope.currentUser.tenant.oauths);
            $scope.processHeatIndex($rootScope.currentUser.tenant.controlConditions);
            $scope.extensionData.maxExtend = $rootScope.maxTimerHour;
            $scope.maxExtendMax = Math.max($rootScope.maxTimerHour, 8);
            $scope.extensionData.defaultExtend = $rootScope.defaultTimerDuration;
            $scope.isAdvancedPwd = $rootScope.currentUser.tenant.passwordPolicy === APIKEY.pwdPolicy.advanced;
            $scope.isDisablePwd = $rootScope.currentUser.tenant.disablePwdSignIn;
            $scope.allowViewOthers = $rootScope.currentUser.tenant.viewOthers;
            $scope.allowSelfReg = $rootScope.currentUser.tenant.allowSelfReg;
            $scope.allowVisitor = $rootScope.currentUser.tenant.enableInviteVisitor;
            $scope.defaultLang = $rootScope.currentUser.tenant.defaultLanguage;
        });
    /* first load */

        $scope.processOAuths = function(oauths){
            $scope.oauths = [];
            $scope.allowedDomains = [];
            oauths.forEach(function(item){
                if (item.provider === APIKEY.provider.email){
                    $scope.allowedDomains.push(item);
                } else {
                    var showCount = Math.min(5, Math.floor(item.code.length / 2));
                    var tmp = '';
                    for (var i=0; i<item.code.length-showCount; i++){
                        tmp += 'x';
                    }
                    item.maskedCode = item.code.substring(0, showCount) + tmp;
                    $scope.oauths.push(item);
                }
            });
        }

        $scope.processHeatIndex = function(heatIndex){
            $scope.heatIndexArr = [];
            if (heatIndex){
                heatIndex.sort(Service.getSorter("index"));
                for (var i=0; i<heatIndex.length; i++){
                    $scope.heatIndexArr.push({
                        humidity: [Math.round(heatIndex[i].humidityLow*100), Math.round(heatIndex[i].humidityHigh*100)],
                        temperature: [Math.round(heatIndex[i].temperatureLow*10)/10, Math.round(heatIndex[i].temperatureHigh*10)/10]
                    });
                }
            }
            $scope.heatIndexArrOrig = jQuery.extend(true, [], $scope.heatIndexArr);
        }

        $scope.generalApiKey = function(){
            $scope.btnStatus.saving = true;
            caller.call(Api.refreshTenantApiKey()).then(function(res){
                $scope.apiKey = res;
                $rootScope.currentUser.tenant.apiKey = res;
                $scope.btnStatus.saving = false;
            }, function(err){
                if (err === KEY.ignore)
                    return;
                $rootScope.generalErrorHandler(true);
                $scope.btnStatus.saving = false;
            });
        }

    /* ms tenant id */
        $scope.editMsTenantId = function(isEdit){
            $scope.isEditMsId = isEdit;
            if (!isEdit)
                $scope.externalTenantId = $rootScope.currentUser.tenant.serviceCredentials.length ? $rootScope.currentUser.tenant.serviceCredentials[0].providerTenantCode : null;
        }

        $scope.saveMsTenantId = function(){
            $scope.btnStatus.savingMsId = true;
            caller.call(Api.setProviderTenantId($scope.externalTenantId)).then(function(res){
                if ($rootScope.currentUser.tenant.serviceCredentials.length){
                    if (res){
                        $rootScope.currentUser.tenant.serviceCredentials[0].providerTenantCode = res.providerTenantCode;
                    } else {
                        $rootScope.currentUser.tenant.serviceCredentials.pop();
                    }
                } else {
                    if (res){
                        $rootScope.currentUser.tenant.serviceCredentials.push({providerTenantCode: res.providerTenantCode});
                    } else {
                        //do nothing
                    }
                }
                $scope.editMsTenantId(false);
                $scope.btnStatus.savingMsId = false;
            }, function(err){
                if (err === KEY.ignore)
                    return;
                if (err.graphQLErrors && err.graphQLErrors[0].message && err.graphQLErrors[0].message.indexOf("400 Bad Request") > -1){
                    $rootScope.showError(Service.translate("error.invalidMsId"));
                } else {
                    $rootScope.showError(Service.translate("error.generalSaveFail"));
                }
                $scope.btnStatus.savingMsId = false;
            });
        }
    /* ms tenant id */

    /* ms consent */
        $scope.msConsentLink = '';
        $scope.copied = false;
        $scope.$watchCollection('[isEditMsId, btnStatus.savingMsId, currentUser.tenant.serviceCredentials[0].providerTenantCode]', function(newArr, oldArr) {
            if (!newArr[0] && !newArr[1] && newArr[2]){
                $scope.msConsentLink = Service.getMsConsentLink(newArr[2]);
            } else {
                $scope.msConsentLink = "";
            }
        });

        $scope.copyMsConsentLink = function(){
            navigator.clipboard.writeText($scope.msConsentLink);
            $scope.copied = true;
            $timeout(function(){
                $scope.copied = false;
            }, 2000);
        }
    /* ms consent */

    /* ms subscription list */
        caller.call(Api.getSubscriptions()).then(function(res){
            $scope.subscriptions = res;
        }, function(err){
            if (err === KEY.ignore)
                return;
            $rootScope.generalErrorHandler(true);
        });
    
        $scope.deleteSubscription = function(s){
            var id = s.id;
            $rootScope.deletePopup.show("company.deleteSubscriptionTitle", "company.deleteSubscriptionDesc", s.resourceCode, function(){
                caller.call(Api.deleteSubscription(id)).then(function(){
                    Service.deleteArrItem($scope.subscriptions, id);
                    $rootScope.deletePopup.close();
                }, function(err){
                    if (err === KEY.ignore)
                        return;
                    $rootScope.showError(Service.translate("error.generalDelItemFail"));
                });
            });            
        }
    /* ms subscription list */

    /* habitap id */
        $scope.editHabitapId = function(isEdit){
            $scope.isEditHabitapId = isEdit;
            if (!isEdit)
                $scope.habitapId = $scope.currentHabitapId;
        }

        $scope.saveHabitapId = function(){
            $scope.btnStatus.savingHabitapId = true;
            var hId = $scope.habitapId.trim();
            if (hId) {
              var action = $scope.currentHabitapId ? Api.updateTenantHabitapMapping($scope.tenantId, $scope.habitapId) : Api.createTenantHabitapMapping($scope.tenantId, $scope.habitapId);
            } else {
              var action = Api.deleteTenantHabitapMapping($scope.tenantId);
            }
            caller.call(action).then(function(res){
                $scope.currentHabitapId = res.clientId ? res.clientId : null;
                $scope.editHabitapId(false);
                $scope.btnStatus.savingHabitapId = false;
            }, function(err){
                if (err === KEY.ignore)
                    return;
                $rootScope.showError(Service.translate("error.generalSaveFail"));
                $scope.btnStatus.savingHabitapId = false;
            });
        }
    /* habitap id */

    /* advanced pwd */
        $scope.editAdvancedPwd = function(isEdit){
            $scope.isAdvancedPwd = $rootScope.currentUser.tenant.passwordPolicy === APIKEY.pwdPolicy.advanced;
            $scope.isEditAdvPwd = isEdit;
        }
        $scope.saveAdvancedPwd = function(){
            $scope.btnStatus.savingAdvPwd = true;
            caller.call(Api.updatePwdPolicy($scope.isAdvancedPwd ? APIKEY.pwdPolicy.advanced : APIKEY.pwdPolicy.general)).then(function(tenant){
                $rootScope.currentUser.tenant.passwordPolicy = tenant.passwordPolicy;
                $scope.btnStatus.savingAdvPwd = false;
                $scope.isEditAdvPwd = false;
            }, function(err){
                if (err === KEY.ignore)
                    return;
                $scope.isAdvancedPwd = $rootScope.currentUser.tenant.passwordPolicy === APIKEY.pwdPolicy.advanced;
                $rootScope.generalErrorHandler(true);
                $scope.btnStatus.savingAdvPwd = false;
            });
        }
    /* advanced pwd */

    /* disable pwd */
        $scope.editDisablePwd = function(isEdit){
            $scope.isDisablePwd = $rootScope.currentUser.tenant.disablePwdSignIn;
            $scope.isEditDisablePwd = isEdit;
        }
        $scope.saveDisablePwd = function(){
            $scope.btnStatus.savingDisablePwd = true;
            caller.call(Api.updateDisablePwd($rootScope.currentUser.tenant.id, $scope.isDisablePwd)).then(function(tenant){
                $rootScope.currentUser.tenant.disablePwdSignIn = tenant.disablePwdSignIn;
                $scope.btnStatus.savingDisablePwd = false;
                $scope.isEditDisablePwd = false;
            }, function(err){
                if (err === KEY.ignore)
                    return;
                $scope.isDisablePwd = $rootScope.currentUser.tenant.disablePwdSignIn;
                $rootScope.generalErrorHandler(true);
                $scope.btnStatus.savingDisablePwd = false;
            });
        }
    /* disable pwd */

    /* extension */
        $scope.editingExtension = false;
        $scope.extensionData = { maxExtend: 48 };  //must have large default value, otherwise display will cap at 100 for the first load

        $scope.editExtension = function(){
            $scope.editingExtension = true;
        }
        $scope.cancelExtension = function(){
            $scope.editingExtension = false;
            $scope.extensionData.maxExtend = $rootScope.maxTimerHour;
            $scope.extensionData.defaultExtend = $rootScope.defaultTimerDuration;
        }
        $scope.saveExtension = function(){
            $scope.btnStatus.savingExtension = true;
            caller.call(Api.updateExtension($rootScope.currentUser.tenant.id, $rootScope.currentUser.tenant.name, $scope.extensionData.maxExtend, $scope.extensionData.defaultExtend)).then(function(res){
                $rootScope.defaultTimerDuration = $scope.extensionData.defaultExtend = res.defaultExtensionMinutes;
                $rootScope.maxTimerHour = $scope.extensionData.maxExtend = res.maxExtensionHours;
                $scope.btnStatus.savingExtension = false;
                $scope.editingExtension = false;
            }, function(err){
                if (err === KEY.ignore)
                    return;
                $rootScope.generalErrorHandler(true);
                $scope.btnStatus.savingExtension = false;
            });
        }
    /* extension */

    /* self reg */
        $scope.editAllowSelfRegistration = function(isEdit){
            $scope.allowSelfReg = $rootScope.currentUser.tenant.allowSelfReg;
            $scope.isEditAllowSelfReg = isEdit;
        }
        $scope.saveAllowSelfRegistration = function(){
            $scope.btnStatus.savingAllowSelfReg = true;
            caller.call(Api.setAllowSelfRegistration($scope.allowSelfReg)).then(function(tenant){
                $rootScope.currentUser.tenant.allowSelfReg = tenant.allowSelfReg;
                $scope.btnStatus.savingAllowSelfReg = false;
                $scope.isEditAllowSelfReg = false;
            }, function(err){
                if (err === KEY.ignore)
                    return;
                $scope.allowSelfReg = $rootScope.currentUser.tenant.allowSelfReg;
                $rootScope.generalErrorHandler(true);
                $scope.btnStatus.savingAllowSelfReg = false;
            });
        }
    /* self reg */

    /* default lang */
        $scope.defaultLangDropdownOpt = {
            autoWidth: true,
            dataSource: [{
                text: Service.translate("lang.EN_US"),
                value: "EN_US",
            }, {
                text: Service.translate("lang.ZH_HANT"),
                value: "ZH_HANT",
            }, {
                text: Service.translate("lang.ZH_HANS"),
                value: "ZH_HANS",
            }, {
                text: Service.translate("lang.JA_JA"),
                value: "JA_JA",
            }, {
                text: Service.translate("lang.TH_TH"),
                value: "TH_TH",
            }],
            dataTextField: "text",
            dataValueField: "value"
        }
        $scope.displayLangLbl = function(code) {
          return Service.translate("lang." + code);
        }
        $scope.editDefaultLang = function(isEdit){
            $scope.isEditDefaultLang = isEdit;
            $scope.defaultLang = $rootScope.currentUser.tenant.defaultLanguage;
        }
        $scope.saveEditDefaultLang = function(){//TODOricky api
            $scope.btnStatus.savingDefaultLang = true;
            caller.call(Api.updateDefaultLanguage($scope.tenantId, $scope.defaultLang)).then(function(tenant){
                $rootScope.currentUser.tenant.defaultLanguage = tenant.defaultLanguage;
                $scope.btnStatus.savingDefaultLang = false;
                $scope.isEditDefaultLang = false;
            }, function(err){
                if (err === KEY.ignore)
                    return;
                $scope.defaultLang = $rootScope.currentUser.tenant.defaultLanguage;
                $rootScope.generalErrorHandler(true);
                $scope.btnStatus.savingDefaultLang = false;
            });
        }
    /* default lang */

    /* view other */
        $scope.editViewOthers = function(isEdit){
            $scope.allowViewOthers = $rootScope.currentUser.tenant.viewOthers;
            $scope.isEditViewOthers = isEdit;
        }
        $scope.saveViewOthers = function(){
            $scope.btnStatus.savingViewOthers = true;
            caller.call(Api.setViewOthers($rootScope.currentUser.tenant.id, $scope.allowViewOthers)).then(function(tenant){
                $rootScope.currentUser.tenant.allowSelfReg = tenant.viewOthers;
                $scope.btnStatus.savingViewOthers = false;
                $scope.isEditViewOthers = false;
            }, function(err){
                if (err === KEY.ignore)
                    return;
                $scope.allowViewOthers = $rootScope.currentUser.tenant.viewOthers;
                $rootScope.generalErrorHandler(true);
                $scope.btnStatus.savingViewOthers = false;
            });
        }
    /* view other */

    /* visitor */
        $scope.editAllowVisitor = function(isEdit){
            $scope.allowVisitor = $rootScope.currentUser.tenant.enableInviteVisitor;
            $scope.isEditAllowVisitor = isEdit;
        }
        $scope.saveAllowVisitor = function(){
            $scope.btnStatus.savingAllowVisitor = true;
            caller.call(Api.setEnableVisitor($scope.allowVisitor)).then(function(tenant){
                $rootScope.currentUser.tenant.enableInviteVisitor = tenant.enableInviteVisitor;
                $scope.btnStatus.savingAllowVisitor = false;
                $scope.isEditAllowVisitor = false;
                $scope.visitorWin.close();
            }, function(err){
                if (err === KEY.ignore)
                    return;
                $scope.allowVisitor = $rootScope.currentUser.tenant.enableInviteVisitor;
                $rootScope.generalErrorHandler(true);
                $scope.btnStatus.savingAllowVisitor = false;
            });
        }

        $scope.visitorWinOpt = {
            title: Service.translate("company.titleVisitor"),
            width: "600px",
            modal: true,
            draggable: false,
            visible: false,
            resizable: false,
            actions: ["Close"]
        }
        $scope.showVisitorWin = function(withActionBtn){
            $scope.showVisitorPopupBtn = withActionBtn;
            setTimeout(function(){
                $scope.visitorWin.center().open();
            });
        }
        $scope.clickSaveEnableVisitor = function(){
            if ($scope.allowVisitor){
                $scope.showVisitorWin(true);
            } else {
                $scope.saveAllowVisitor();
            }
        }
    /* visitor */

    /* domain */
        $scope.addDomainData = {};
        $scope.addDomainWinOpt = {
            title: Service.translate("company.popup.addAllowedDomain"),
            width: "650px",
            modal: true,
            draggable: false,
            visible: false,
            resizable: false,
            actions: ["Close"],
        }
        $scope.showAddDomainWin = function(){
            $scope.addDomainData.domain = '';
            setTimeout(function(){
                $scope.addDomainWin.center().open();
            });
        }

        $scope.saveDomain = function(){
            $scope.btnStatus.savingDomain = true;
            var domain = ($scope.addDomainData.domain || '').trim().toLowerCase();
            caller.call(Api.addOAuthBinding($rootScope.currentUser.tenant.id, domain, APIKEY.provider.email)).then(function(tenant){
                $scope.processOAuths(tenant.oauths);
                $scope.btnStatus.savingDomain = false;
                $scope.addDomainWin.close();
            }, function(err){
                if (err === KEY.ignore)
                    return;
                $scope.btnStatus.savingDomain = false;
                $rootScope.showError(Service.translate("error.generalSaveFail"));
            });
        }

        $scope.deleteDomain = function(code){
            $rootScope.deletePopup.show("company.deleteDomainTitle", "company.deleteDomainDesc", code, function(){
                caller.call(Api.removeOAuthBinding($rootScope.currentUser.tenant.id, code)).then(function(tenant){
                    $scope.processOAuths(tenant.oauths);
                    $rootScope.deletePopup.close();
                }, function(err){
                    if (err === KEY.ignore)
                        return;
                    $rootScope.showError(Service.translate("error.generalDelItemFail"));
                });
            });
        }
    /* domain */

    /* OAuth */
        $scope.addOAuthData = {};
        $scope.addOAuthWinOpt = {
            title: Service.translate("company.popup.addOAuthProvider"),
            width: "650px",
            modal: true,
            draggable: false,
            visible: false,
            resizable: false,
            actions: ["Close"],
        }
        $scope.providerDropdownOpt = {
            dataSource: [{
                text: Service.translate("company.popup.google"),
                value: APIKEY.provider.google,
            }, {
                text: Service.translate("company.popup.microsoft"),
                value: APIKEY.provider.microsoft,
            }],
            dataTextField: "text",
            dataValueField: "value"
        }
        $scope.showAddOAuthWin = function(){
            $scope.addOAuthData.provider = APIKEY.provider.microsoft;
            $scope.addOAuthData.code = '';
            setTimeout(function(){
                $scope.addOAuthWin.center().open();
            });
        }

        $scope.saveOAuth = function(){
            $scope.btnStatus.savingOAuth = true;
            var code = ($scope.addOAuthData.code || '').trim();
            caller.call(Api.addOAuthBinding($rootScope.currentUser.tenant.id, code, $scope.addOAuthData.provider)).then(function(tenant){
                $scope.processOAuths(tenant.oauths);
                $scope.btnStatus.savingOAuth = false;
                $scope.addOAuthWin.close();
            }, function(err){
                if (err === KEY.ignore)
                    return;
                $scope.btnStatus.savingOAuth = false;
                $rootScope.showError(Service.translate("error.generalSaveFail"));
            });
        }

        $scope.deleteOAuth = function(item){
            $rootScope.deletePopup.show("company.deleteOAuthTitle", "company.deleteOAuthDesc", item.maskedCode, function(){
                caller.call(Api.removeOAuthBinding($rootScope.currentUser.tenant.id, item.code)).then(function(tenant){
                    $scope.processOAuths(tenant.oauths);
                    $rootScope.deletePopup.close();
                }, function(err){
                    if (err === KEY.ignore)
                        return;
                    $rootScope.showError(Service.translate("error.generalDelItemFail"));
                });
            });
        }
    /* OAuth */

    /* heatmap index */
        $scope.editingHeatIndex = false;
        $scope.tempRangeSliderOpt = {
            tickPlacement: "none",
            leftDragHandleTitle: "",
            rightDragHandleTitle: "",
            tooltip: { enabled: false },
            largeStep: 0.5,
            smallStep: 0.5,
            max: KEY.maxTemp,
            min: KEY.minTemp
        }
        $scope.humidityRangeSliderOpt = {
            tickPlacement: "none",
            leftDragHandleTitle: "",
            rightDragHandleTitle: "",
            tooltip: { enabled: false },
            largeStep: 1,
            smallStep: 1,
            max: KEY.maxHumidity,
            min: KEY.minHumidity
        }

        $scope.editHeatIndex = function(){
            $scope.editingHeatIndex = true;
        }
        $scope.cancelEditHeatIndex = function(){
            $scope.editingHeatIndex = false;
            $scope.heatIndexArr = $scope.heatIndexArrOrig;
        }

        $scope.addHeatIndex = function(){
            $scope.heatIndexArr.push({
                humidity: [KEY.minHumidity, KEY.maxHumidity],
                temperature: [KEY.minTemp, KEY.maxTemp]
            });
        }
        $scope.deleteHeatIndex = function(i){
            $scope.heatIndexArr.splice(i, 1);
        }

        $scope.saveHeatIndex = function(){
            $scope.btnStatus.savingHeatIndex = true;
            caller.call(Api.updateHeatIndexDefinition($scope.heatIndexArr.map(function(item, i){
                return {
                    index: i,
                    priority: i,
                    temperatureLow: item.temperature[0],
                    temperatureHigh: item.temperature[1],
                    humidityLow: item.humidity[0]/100,
                    humidityHigh: item.humidity[1]/100,
                }
            }))).then(function(res){
                $rootScope.currentUser.tenant.controlConditions = res;
                $scope.processHeatIndex(res);
                $scope.editingHeatIndex = false;
                $scope.btnStatus.savingHeatIndex = false;
            }, function(err){
                if (err === KEY.ignore)
                    return;
                $scope.btnStatus.savingHeatIndex = false;
                $rootScope.showError(Service.translate("error.generalSaveFail"));
            });
        }
    /* heatmap index */

        $scope.$on('$destroy', function() {
            console.log("companyController destroy");
            caller.cancel();
        });
    }
})();